/* ----------------------------- */
/* == All indexes             */
/* ----------------------------- */

////
/// @group indexes
////

/// Lowest index
/// @type z-index
$index-lowest: 1;

/// Header index
/// @type z-index
$index-header: 5;


// Parallax

/// Parallax bloc
/// @type z-index
$index-parallax-bloc: 3;

/// Parallax picture
/// @type z-index
$index-parallax-picture: 3;

/// Parallax badge
/// @type z-index
$index-parallax-badge: 2;

/// Parallax Glow
/// @type z-index
$index-parallax-glow-background: 0;

/// Parallax Glow
/// @type z-index
$index-parallax-glow-foreground: 4;