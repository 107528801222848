/* ----------------------------- */
/* == All Fixed Sizes             */
/* ----------------------------- */

////
/// @group Size
////

/// layout
/// @type size
$max-width-layout: 1024px;

/// layout
/// @type size
$max-width-layout-md: 800px;

/// Layout - header
/// @type size
$layout-header-height: 70px;

/// Spacing
/// @type size
$spacing-hero-bottom: 100px;

/// Gutter
/// @type size
$gutter-xs: 12px;
$gutter: 20px;