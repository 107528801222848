/// Set size to an element
/// @param {string} $width
/// @example scss - font-size mixin
///  @include font-size(14px);
///  // font-size: 14px;
///  // font-size: 1.4rem;
@mixin font-size($size) {
  @include rem('font-size', $size);
}

@mixin font-family($name, $weight: 300) {
  font-family: $name;
  font-weight: #{$weight};
}

@mixin font($name, $weight: 300, $size: null) {
  @include font-family($name, $weight);
  @include font-size($size);
}