html {
  height: 100%;
  font-size: 10px;

  background-color: #181b18;
}

body {
  width: 100%;
  min-height: 100%;

  overflow-x: hidden;

  background-image: url('#{$path-image}fond.jpg');
  background-repeat: no-repeat;
  background-position: center top;

  color: $color-light;
  @include font($font-proximaRegular, normal, $font-size-root);
  line-height: 1.2;
}

main {

}

* {
  box-sizing: border-box;
}

// default
b {
  font-weight: 500;
}

// Global helper
.visually-hidden {
  @include visually-hidden;
}

.clearfix {
  @include clearfix;
}

// Text helpers
.text {
  &--ac {
    text-align: center;
  }
}

// Block helpers
.block {

  &--center {
    margin-left: auto;
    margin-right: auto;
  }
}
// sections
.section-wrapper {
  max-width: $max-width-layout + $gutter-xs*2;
  padding-left: $gutter-xs;
  padding-right: $gutter-xs;
  margin: 0 auto;

  &--md {
    max-width: $max-width-layout-md;
  }

  @media screen and (min-width: $breakpoint-xs) {
    max-width: $max-width-layout + $gutter*2;
    padding-left: $gutter;
    padding-right: $gutter;
  }
}

// colors
.color-primary {
  color: $color-primary;
}
.color-secondary {
  color: $color-secondary;
}