.partners {

  .title-1 {
    $space-top: 240px;
    position: relative;
    padding-top: 30px;

    @media screen and (min-width: $breakpoint-xs) {
      padding-top: 50px;
    }

    @media screen and (min-width: $breakpoint-md) {
      padding-top: $space-top;
    }

    @include border-animation($space-top);
  }

  &-selection {

    display: table;
    table-layout: fixed;
    width: 100%;

    li {
      display: table-cell;
      text-align: center;
    }

    button {
      display: block;
      width: 100%;
      padding: 20px 0;
      border: none;
      background: none;

      text-align: center;
      text-transform: uppercase;
      font-family: $font-misoLight;
      @include font-size(25px);

      &.active {
        color: $color-secondary;
        cursor: default;
      }

      @media screen and (min-width: $breakpoint-xs) {
        @include font-size(36px);
      }

    }

  }

  &-wrapper {
    position: relative;
    margin-bottom: 30px;

    @media screen and (min-width: $breakpoint-xs) {
      margin-bottom: 50px;
    }

    &-arrow  {


      @include triangle(
              $direction: top,
              $position: bottom 100% left 25%,
              $color: #FFF
      );
      margin-left: - 1em;

      transition: left .4s ease-in-out;
    }
  }

  &-group {
    $img-margin: 0px;
    display: none;
    position: relative;
    padding: 50px;

    background-color: #fff;

    @media screen and (min-width: $breakpoint-md) {
      padding: 50px 35px;
    }

    &--active {
      display: block;
    }

    p {
      margin-left: -$img-margin;
      margin-right: -$img-margin;
      text-align: center;
    }

    img {
/*      display: block;
      margin: 10px auto;*/
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;

      @media screen and (min-width: $breakpoint-sm) {
        display: inline-block;
        vertical-align: middle;
        margin-left: $img-margin;
        margin-right: $img-margin;

        max-width: 100%;
        height: auto;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}