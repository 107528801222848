.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: $index-header;

  min-height: $layout-header-height;

  width: 100%;
  //min-height: $layout-header-height;

  background-color: $rgba-bg;

  transition: background .4s ease-in-out;

  &.scrolled {
    .logo {
      a {
        width: 85px;
      }
    }
  }

  .burger--active + &-nav {
    left: 0;
  }

  &-nav {
    @include absolute(top $layout-header-height left -100%);
    transition: left .4s ease-in-out;

    max-width: 1024px;
    margin: 0 auto;

    text-transform: uppercase;

    ul {
      padding-top: 15px;
      padding-bottom: 15px;

      background-color: $color-dark;
    }

    li {
    }

    .logo  {
      a {
        display: block;
        position: relative;
        margin: 0 auto;
        padding: 0;
        width: 0;
        transition: width .4s ease;

        &:after {
          display: none;
        }

        img {
          @include absolute(left 50% top 50%);
          transform: translate(-50%,-50%);

          width: 80%;
          height: 80%;
        }

        &.active {
          background: none;
        }
      }

    }

    a {
      display: block;
      position: relative;

      color: $color-light;
      text-decoration: none;
      letter-spacing: 2px;

      padding: 10px 15px;

      &:hover {
        color: $color-primary;

        &:after {
          left: 0;
          opacity: 1;
        }
      }

      &.active {
        color: $color-primary;

        background-color: $color-dark;

        &:after {
          left: 0;
          opacity: 1;
        }
      }


    }

    @media screen and (min-width: $breakpoint-md) {
      position: relative;
      top: auto;
      left: auto;

      ul {
        display: table;
        width: 100%;

        background: none;
        padding: 0;
      }

      li {
        display: table-cell;
        vertical-align: middle;

        text-align: center;
      }

      a {

        overflow: hidden;

        padding: pxToEm(25px);

        min-height: $layout-header-height;


        &:after {
          content: "";
          @include absolute(bottom 0 left -100%);
          width: 100%;
          height: 4px;

          background-color: $color-primary;
          opacity: .4;
          transition: all .4s ease-in-out;
        }

      }
    }
  }
}