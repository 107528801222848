.footer {
  padding-top: 40px;
  padding-bottom: 50px;

  ul {
    max-width: $max-width-layout;
    margin: 0 auto;
    padding: 15px 0;
    border-top: 2px solid $color-grey;
  }

  li {
    margin-bottom: 10px;

    @include font-size(12px);
    text-align: center;

    @media screen and (min-width: $breakpoint-md) {
      display: inline-block;

      margin-bottom: 0;
      margin-right: 25px;
      padding-right: 25px;
      border-right: 1px solid $color-light;

      text-align: left;

      &:last-child {
        margin: 0;
        padding-right: 0;
        border: none;
      }
    }
  }

  a {
    color: $color-light;
  }
}