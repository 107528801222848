.burger {
  $burger-width: 30px;
  $burger-height: $burger-width * .75;

  $burger-dash-height: $burger-height / 5;

  @include absolute(top 50% left 15px);
  @include size($burger-width, $burger-height);
  margin-top: -($burger-height/2);

  transform: rotate(0deg);
  transition: .5s ease-in-out;

  padding: 0;
  outline-width: 0px;
  border: none;
  background: none;
  cursor: pointer;

  span {
    position: absolute;
    left: 0;
    display: block;
    @include size(100%, $burger-dash-height);
    /*border-radius: $burger-dash-height;*/

    background-color: $color-primary;

    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0;
    transform-origin: left center;
  }

  span:nth-child(2) {
    top: $burger-dash-height * 2;
    transform-origin: left center;
  }

  span:nth-child(3) {
    top: $burger-dash-height * 4;
    transform-origin: left center;
  }

  &--active span:nth-child(1) {
    transform: rotate(45deg);
    top: - ($burger-width * 0.05);
    left: 8px;
  }

  &--active span:nth-child(2) {
    width: 0;
    opacity: 0;
  }

  &--active span:nth-child(3) {
    transform: rotate(-45deg);
    top: $burger-width * .65;
    left: 8px;
  }

  @media screen and (min-width:$breakpoint-md) {
    display: none;
  }
}