// Override bootstrap col (allow different gutter size)
.row {

  @media screen and (max-width: $breakpoint-xs-max) {
    margin-left: -$gutter-xs;
    margin-right: -$gutter-xs;

    [class^="col-"] {
      padding-left: $gutter-xs;
      padding-right: $gutter-xs;
    }
  }

  @media screen and (min-width: $breakpoint-xs) {
    &--lg {
      $gutter: 35px;
      margin-left: -$gutter;
      margin-right: -$gutter;

      [class^="col-"] {
        padding-left: $gutter;
        padding-right: $gutter;
      }
    }

    &--xs {
      $gutter: 5px;
      margin-left: -$gutter;
      margin-right: -$gutter;

      [class^="col-"] {
        padding-left: $gutter;
        padding-right: $gutter;
      }
    }
  }
}