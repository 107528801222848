.section-404 {
  @include absolute(top 50% left 50%);
  width: 100%;
  max-width: 740px;
  padding: 0 $gutter;

  transform: translate(-50%, -50%);

  color: $color-light;
  text-align: center;

  font-family: $font-proximaLight;

  @include font-size(25px);

  &-logo {
    width: 100%;
    max-width: 320px;
    height: auto;

    margin-bottom: 20px;
  }

  &-title {
    @include font-size(70px);
    margin-bottom: 20px;

    color: $color-secondary;
    font-weight:bold;

    @media screen and (min-width: $breakpoint-sm) {
      @include font-size(140px);
    }

    @media screen and (min-width: $breakpoint-md) {
      @include font-size(220px);
    }
  }

  p {
    margin-bottom: 20px;
  }

  &-wrapper {
    padding: 25px;

    @media screen and (min-width: $breakpoint-sm) {
      padding: 50px;
    }

    background-color: rgba($color-dark, .8);
  }

  &-back {
    display: block;
    width: 100%;
    padding: 15px;

    background-color: $color-primary;

    text-decoration: none;
    text-transform: uppercase;
    color: $color-dark;
    letter-spacing: 1px;
    font-family: $font-proximaBold;

    &:hover {
      text-decoration: none;

      background-color: $color-secondary;
      color: $color-dark;
    }

  }
}

#canvas-404 {
  display: block;

  opacity: .4;
}