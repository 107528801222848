/* ----------------------------- */
/* == All colors             */
/* ----------------------------- */

////
/// @group colors
////

/// Primary
/// @type Color
$color-primary: #80AD08;

/// Primary
/// @type Color
$color-secondary: #CDD70C;


/// Error
/// @type Color
$color-error: #ff152c;

  /// Light
/// @type Color
$color-light: #D0D0D0;

/// dark
/// @type Color
$color-dark: #141414;

/// dark grey
/// @tpye Color
$color-dark-grey: #212121;

/// Grey
/// @type Color
$color-grey: #5B5B5B;

/// Rgba background
/// @type Color
$rgba-bg: rgba($color-dark, .9);

/// Rgba background primary
/// @type Color
$rgba-bg-primary: rgba($color-primary, .7);

/// Rgba background secondary
/// @type Color
$rgba-bg-secondary: rgba($color-secondary, .7);



