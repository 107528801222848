.form {
  .form-row {
    margin-bottom: 15px;
    &:last-child{
      margin-bottom: 0;
    }
  }

  .error-info,
  .success-info {
    font-family: $font-misoLight;
    @include font-size(30px);
  }

  .error-info {
    color: $color-error;
  }

  .success-info {
    color: $color-primary;
  }
  
  textarea {
    display: block;
    resize: none;
  }
}