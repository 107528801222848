.knowledge {

  &-title {
    $heading-pt: 250px;
    position: relative;
    padding-top: 30px;

    @media screen and (min-width: $breakpoint-xs) {
      padding-top: 50px;
    }

    @media screen and (min-width: $breakpoint-md) {
      padding-top: $heading-pt;
    }

    @include border-animation($heading-pt);
  }

  .slider {

    &-nav {

      ul {
        display: table;
        width: 100%;
        margin: 0 auto;
        padding: 20px 0;
      }

      li {
        display: table-cell;
        text-align: center;
      }

      @media screen and (min-width: $breakpoint-md) {
        margin-bottom: 50px;

        ul {
          width: auto;
          padding: 25px 0;
        }

        li {
          padding: 0 25px;
        }

      }

      button {

        background: none;
        border: none;

        color: $color-primary;
        text-transform: uppercase;
        outline-width: 0;

        &.active {
          color: $color-secondary;

          [class*="icon"] {
            background-color: $color-secondary;
          }
        }

        &:hover {
          color: $color-secondary;
        }

        @media screen and (max-width: $breakpoint-xs-max) {
          padding: 0;
        }
      }


      [class*="icon"] {
        display: block;
        @include size(50px);
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: $color-primary;

        color: $color-dark;
        line-height: 50px;
        @include font-size(35px);

        @media screen and (min-width: $breakpoint-xs) {
          @include size(70px);
          line-height: 70px;
        }

        @media screen and (min-width: $breakpoint-md) {
          @include size(85px);
          line-height:85px;
          @include font-size(45px);
        }
      }

      &-label {
        display: none;

        @media screen and (min-width: $breakpoint-sm) {
          display: block;
        }
      }
    }

    &-wrapper {
      position: relative;

      @media screen and (max-width: $breakpoint-md-max) {
        &-arrow {
          display: none;

          transition: left .4s ease-in-out;

          @include triangle(
                  $direction: top,
                  $position: bottom 100% left 0,
                  $color: $color-dark
          );
          margin-left: -1em;

        }
      }
    }

    &-item {
      $transition-duration: .4s;
      display: none;
      position: relative;

      overflow: hidden;

      &--active {
        display: block;

        .slider-item-box {
          @media screen and (min-width: $breakpoint-md) {
            right: 0;
          }
        }

        .slider-item-image {
          left: 0;
        }
      }

      &-image {
        display: none;

        @media screen and (min-width: $breakpoint-md) {
          display: block;
          width: 66%;
          height: 515px;
          overflow: hidden;

          position: relative;
          left: -100%;
          transition: left $transition-duration ease;

          img {
            min-height: 100%;
            width: auto;
          }
        }
      }

      &-box {
        padding: 20px;
        background-color: $color-dark;

        @media screen and (min-width: $breakpoint-xs){
          padding:40px 20px;
        }

        @media screen and (min-width: $breakpoint-md) {
          @include absolute(right -100% top 50%);
          padding: 70px 40px 50px 40px;
          transform: translateY(-50%);
          width: 50%;
          background-color: $rgba-bg;
          transition: right $transition-duration ease;
        }
      }

      &-title {
        display: block;
        margin-bottom: 40px;

        text-align: center;
        color: $color-secondary;
        font-family: $font-misoLight;
        @include font-size(45px);
      }

      &-hook {
        display: block;
        margin-bottom: 25px;

        font-family: $font-proximaBold;
        font-style: italic;
        letter-spacing: 1px;

        @media screen and (min-width: $breakpoint-md) {
          + p {
            text-align: justify;
          }
        }
      }
    }
  }
}