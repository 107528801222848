.hero {
  position: relative;
  z-index: $index-lowest;
  padding: ($layout-header-height + 30px) $gutter-xs 30px $gutter-xs;
  overflow: hidden;

  @media screen and (min-width: $breakpoint-xs) {
    padding: ($layout-header-height + 30px) $gutter 30px $gutter;
  }

  @media screen and (min-width: $breakpoint-md) {
    padding: 0;
    overflow: visible;
  }

  &-wrapper {
    @media screen and (min-width: $breakpoint-md) {
      width: 100%;
      height: 740px;

      overflow: hidden;
    }
  }

  &-banner {
    @include absolute(top 50% left 50%);
    z-index: -1;
    transform: translate(-50%, -50%);

    display: block;
    width: auto;
    height: auto;
    min-width: 100%;

    @media screen and (min-width: $breakpoint-md) {
      position: static;
      transform: none;
    }
  }

  &-overlay {
    @include absolute(top 0 right 0 bottom 0 left 0);
    pointer-events: none;
    background-repeat: repeat;
  }

  &-label {
    position: relative;

    @media screen and (min-width: $breakpoint-md) {
      @include absolute(left 50% bottom (-$spacing-hero-bottom));
      transform: translateX(-50%);
      @include rem(padding, 40px 50px);
    }

    width: 100%;
    max-width: $max-width-layout-md;
    margin: 0 auto;
    @include rem(padding, 20px);

    background-color: $rgba-bg;

    text-align: center;

    &-logo {
      display: block;
      margin: 0 auto;

      max-width: 360px;

      @include rem(margin-bottom, 50px);
    }

    &-title {
      display: block;
      @include rem(margin-bottom, 15px);

      font-family: $font-misoLight;
      @include font-size(30px);
      color: $color-primary;
    }

    p {
      display: none;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      @include rem(margin-bottom, 25px);

      font-family: $font-proximaLight;

    }

    strong {
      display: none;
      @include rem(margin-bottom, 25px);

      font-family: $font-proximaBold;
      letter-spacing: 2px;
      @include font-size(20px);
    }

    .icon-arrow-down {
      display: block;
      margin: 0 auto;

      color: $color-primary;
      @include font-size(30px);
    }

    @media screen and (min-width: $breakpoint-md) {
      p, strong {
        display: block;
      }
    }
  }

}