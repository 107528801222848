@font-face{
  font-family: 'proximanova regular';
  src: url('#{$path-fonts}proximanova-regular-webfont.eot');
  src: url('#{$path-fonts}proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$path-fonts}proximanova-regular-webfont.woff') format('woff'),
  url('#{$path-fonts}proximanova-regular-webfont.ttf') format('truetype'),
  url('#{$path-fonts}proximanova-regular-webfont.svg#webfont') format('svg');
}

@font-face{
  font-family: 'proximanova bold';
  src: url('#{$path-fonts}proximanova-bold-webfont.eot');
  src: url('#{$path-fonts}proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$path-fonts}proximanova-bold-webfont.woff') format('woff'),
  url('#{$path-fonts}proximanova-bold-webfont.ttf') format('truetype'),
  url('#{$path-fonts}proximanova-bold-webfont.svg#webfont') format('svg');
}

@font-face{
  font-family: 'proximanova light';
  src: url('#{$path-fonts}proximanova-light-webfont.eot');
  src: url('#{$path-fonts}proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$path-fonts}proximanova-light-webfont.woff') format('woff'),
  url('#{$path-fonts}proximanova-light-webfont.ttf') format('truetype'),
  url('#{$path-fonts}proximanova-light-webfont.svg#webfont') format('svg');
}

@font-face{
  font-family: 'miso light';
  src: url('#{$path-fonts}miso-light-webfont.eot');
  src: url('#{$path-fonts}miso-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('#{$path-fonts}miso-light-webfont.woff') format('woff'),
  url('#{$path-fonts}miso-light-webfont.ttf') format('truetype'),
  url('#{$path-fonts}miso-light-webfont.svg#webfont') format('svg');
}

// font icon

@font-face {
  font-family: 'icomoon';
  src:  url('#{$path-fonts}icons/fonts/icomoon.eot?fp56h1');
  src:  url('#{$path-fonts}icons/fonts/icomoon.eot?fp56h1#iefix') format('embedded-opentype'),
  url('#{$path-fonts}icons/fonts/icomoon.ttf?fp56h1') format('truetype'),
  url('#{$path-fonts}icons/fonts/icomoon.woff?fp56h1') format('woff'),
  url('#{$path-fonts}icons/fonts/icomoon.svg?fp56h1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pin:before {
  content: '\e906';
}
.icon-cross:before {
  content: "\e905";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-domotic:before {
  content: "\e901";
}
.icon-electricity:before {
  content: "\e902";
}
.icon-network:before {
  content: "\e903";
}
.icon-security:before {
  content: "\e904";
}
