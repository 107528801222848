.certifications {

  .col-md-6:last-child .certification {
    margin-bottom: 0;
  }

  .certification {
    position: relative;
    margin-bottom: ($gutter-xs + 5px);
    padding: 60px 20px;

    border: 1px solid $color-light;
    border-top: none;

    text-align: center;

    @media screen and (min-width: $breakpoint-xs) {
      margin-bottom: ($gutter + 5px);
    }

    @media screen and (min-width: $breakpoint-md) {
      min-height: 500px;
      padding: 60px 50px 80px 50px;
      margin-bottom: 50px;
    }

    // Border
    &:before,
    &:after {
      content: '';
      @include absolute(top 0);
      width: 15%;
      height: 0;
      border-bottom: 1px solid $color-light;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }

    &-title {
      position: absolute;
      top: - pxToEm(10px);
      left: 15%;
      width: 70%;
      padding: 0 10px;

      text-align: center;
      font-family: $font-proximaBold;
      letter-spacing: 2px;
      color: $color-primary;
      @include font-size(16px);
    }

    &-logo {
      display: block;
      margin: 0 auto 30px auto;
    }

    &-desc {
      margin-bottom: 15px;

      font-style: italic;
      @include font-size(18px);
    }

    &-footer {
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 100%;
      padding: 0 50px;

      text-align: center;

      @media screen and (min-width: $breakpoint-md){
        bottom: 60px;
      }
    }
  }
}