@mixin border-animation($height: 100%, $top: 0, $color: $color-primary, $type: dotted) {

  &:before {
    content: '';
    display: none;
    @include absolute(top $top left 50%);
    @include size(1px, 0);
    margin-left: -1px;

    /*border-left: 1px $type $color;*/
    background-image: linear-gradient(to bottom, $color-primary 50%, rgba(255, 255, 255, 0) 0%);
    background-position: left;
    background-size: 1px 15px;
    background-repeat: repeat-y;
    transition: height 1s ease-in-out;

    @media screen and (min-width: $breakpoint-md) {
      display: block;
    }
  }

  &.animate:before {
    height: $height;
  }
}