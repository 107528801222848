.contact {
  position: relative;

  @media screen and (min-width: $breakpoint-md) {
    padding-top: 50px;
  }

  .title-1 {
    $space-top: 120px;
    position: relative;
    padding-top: 30px;

    @media screen and (min-width: $breakpoint-xs) {
      padding-top: 50px;
    }

    @media screen and (min-width: $breakpoint-md) {
      padding-top: $space-top;
    }

    @include border-animation($space-top);
  }

  @include clearfix;

  &-wrapper {
    margin-bottom: 12px;

    @media screen and (min-width: $breakpoint-md) {
      position: relative;
      display: table;
      width: 100%;
    }
  }

  &-col {
    @media screen and (min-width: $breakpoint-md) {
      display: table-cell;
      vertical-align: top;

      &:first-child {
        //position: relative;
        width: 400px;
        padding-right: 35px;
      }

      &:last-child {
        padding-left: 35px;
      }
    }
  }

  &-coords {
    position: relative;
    margin-bottom: 20px;
    padding: 40px 20px;
    border: 1px dotted $color-primary;
    border-top: none;

    // Dashed border
    &:before,
    &:after {
      content: '';
      @include absolute(top 0);
      width: 15%;
      height: 0;
      border-bottom: 1px dotted $color-primary;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }

    &-title {
      position: absolute;
      top: - pxToEm(10px);
      left: 15%;
      width: 70%;
      padding: 0 10px;

      text-align: center;
      font-family: $font-misoLight;
      color: $color-primary;
      @include font-size(25px);
      line-height: 1;

      @media screen and (min-width: $breakpoint-md) {
        @include font-size(40px);
        @include font-size(35px);
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      padding: 60px 50px;

      ul {
        @include absolute(top 50%);
        transform: translateY(-50%);
      }
    }


    @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md-max) {
      ul {
        display: table;
        width: 100%;
      }

      li {
        display: table-cell;
        vertical-align: middle;
        margin: 0;
      }
    }

    li {
      position: relative;
      padding-left: 60px;
      margin-bottom: 35px;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        @include absolute(top 50% left 0);
        transform: translateY(-50%);
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      @include absolute(top 0 left 0 right 0 bottom 0);
      width: 400px;
      margin: 0;
    }
  }

  &-form {

  }

  &-submit {
    float: right;

    // button style
    display: inline-block;
    margin-bottom: 12px;
    padding: 10px;
    background: none;
    border: 2px solid transparent;
    border-bottom-color: $color-primary;

    &:after {
      content: '›';
      position: relative;
      bottom: pxToEm(2px);
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }

    @include font-size(18px);
    color: $color-primary;
    text-transform: uppercase;

    transition: all .4s ease-in-out;

    &:hover {
      border-color: $color-secondary;
      color: $color-secondary;
    }
  }
}