.title-1 {
  display: block;
  margin-bottom: 50px;

  text-align: center;

  > span {
    display: inline-block;
    padding: 20px 20px 12px 20px;

    background-color: $color-primary;

    color: $color-dark;
    text-transform: uppercase;
    font-family: $font-proximaBold;
    letter-spacing: 2px;
    @include font-size(50px);
  }

  > small {
    display: block;

    color: $color-primary;
    font-family: $font-misoLight;
    @include font-size(40px);
  }

  @media screen and (max-width: $breakpoint-xs-max) {
    margin-bottom: 20px;

    > span {
      @include font-size(25px);
    }

    > small {
      @include font-size(25px);
    }
  }
}

.title-2 {
  margin-bottom: 30px;

  text-align: center;
  font-family: $font-misoLight;
  @include font-size(35px);

  @media screen and (min-width: $breakpoint-xs) {
    margin-bottom: 40px;

  }
}