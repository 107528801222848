.team {

  &-wrapper {
    position: relative;

    @media screen and (min-width: $breakpoint-md) {
      padding-top: $spacing-hero-bottom;
      padding-bottom: 280px;
      background-color: rgba(#000, .15);
    }
  }

  &-header {
    position: relative;
    padding-top: 30px;
    @include border-animation(100px);

    @media screen and (min-width: $breakpoint-xs) {
      padding-top: 50px;
    }

    @media screen and (min-width: $breakpoint-md) {
      padding-top: 100px;
    }
  }

  &-member {
    position: relative;
    z-index: $index-parallax-bloc;
    display: block;
    margin: 0 auto;
    margin-bottom: $gutter-xs;
    padding: 30px $gutter;

    background-color: #000;

    text-align: center;

    @include border-animation(280px, 100%);

    @media screen and (min-width: $breakpoint-xs) {
      margin-bottom: $gutter;
    }

    @media screen and (min-width: $breakpoint-sm) {
      padding: 50px;
      margin-bottom: 50px;
    }

    @media screen and (min-width: $breakpoint-md) {
      width: 470px;
      height: 350px;
      margin-bottom: 280px;
      padding-left: 80px;
      padding-right: 80px;

      &.last {
        margin-bottom: 0;
      }
    }

    &-picture {
      display: none;
      position: absolute;
      z-index: $index-parallax-picture;
      pointer-events: none;

      @media screen and (min-width: $breakpoint-md) {
        display: block;
      }

      $drop: 400px;
      &.picture-harry {
        left: -125%;
        top: -55%;

        top: -292px;
        left: -587px;

        top: -280px;

        /*        top: 200px;
                left: -275px;*/
      }

      &.picture-guy {
        top: -135%;
        left: 15%;

        left: 70px;
        top: -472px;

        top: -750px;

        /*        top: 550px;
                left: 350px;*/
      }

      &.picture-ludovic {
        left: -125%;
        top: -40%;

        left: -585px;
        top: -350px;

        /*        top: 1510px;
                left:-280px;*/
      }

    }

    &-name {
      display: block;
      margin-bottom: 25px;

      color: $color-secondary;
      text-transform: uppercase;
      font-family: $font-proximaBold;
      letter-spacing: 2px;
      letter-spacing: 2px;
      @include font-size(27px);
    }

    &-function {
      position: relative;
      display: block;
      margin-bottom: 20px;
      padding-bottom: 20px;
      text-transform: uppercase;

      &:after {
        content: '';
        @include absolute(left 50% bottom 0);
        width: 20px;
        height: 2px;
        margin-left: -10px;
        background-color: $color-light;
      }
    }

    &-desc {
      font-style: italic;
      @include font-size(19px);
    }
  }

  &-glow {
    display: none;

    @media screen and (min-width: $breakpoint-md) {
      display: block;
      @include absolute(top 0 left 0 right 0 bottom 0);
      z-index: 0;
      pointer-events: none;

      background-repeat: no-repeat;
      background-position: top center;
      background-position-y: -200px;

      &--background {
        z-index: $index-parallax-glow-background;
        background-image: url('#{$path-image}glow.png');
      }

      &--foreground {
        z-index: $index-parallax-glow-foreground;
        background-image: url('#{$path-image}glow_blurry.png');
      }
    }
  }

  &-hook {
    text-align: center;

    color: $color-primary;
    font-family: $font-proximaBold;
    letter-spacing: 2px;
    @include font-size(22px);

    p {

      text-align: center;

      text-shadow: 4px 20px 20px $color-dark;
    }

    strong {
      display: block;

      @include font-size(22px);
      text-shadow: 4px 20px 5px $color-dark;
    }

    @media screen and (min-width: $breakpoint-sm) {
      @include font-size(37px);

      strong {
        @include font-size(47px);
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      @include absolute(bottom -50px left 0 right 0);
      z-index: $index-lowest;
    }

  }

  &-badges {
    margin-bottom: 0;
    text-align: center;

    img {
      display: none;

      &:first-child {
        display: block;
        margin: 0 auto;
      }

      @media screen and (min-width: $breakpoint-md) {
        display: block;
        position: absolute;
        z-index: $index-parallax-badge;
      }
    }
  }

  &-footer {
    position: relative;
    display: block;

    color: $color-primary;
    font-family: $font-proximaBold;
    letter-spacing: 2px;
    @include font-size(37px);

    @media screen and (min-width: $breakpoint-md) {
      height: 180px;
    }

/*    p {

      @media screen and (min-width: $breakpoint-md) {
        @include absolute(left 50% top -50px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }

      text-align: center;

      text-shadow: 4px 20px 20px $color-dark;
    }

    strong {
      display: block;
      @include font-size(47px);

      text-shadow: 4px 20px 5px $color-dark;
    }*/

    img {
      display: none;

      @media screen and (min-width: $breakpoint-md) {
        display: block;
        @include absolute(left 50%);
        transform: translateX(-50%);

        height: auto;
      }
    }
  }

  &-mentions {
    position: relative;
    z-index: $index-lowest;
    @include font-size(15px);
    color: $color-light;
    max-width: $max-width-layout;
    margin: 0 auto;
    padding: 0 20px;

    @media screen and (min-width: $breakpoint-md) {
      color: $color-grey;
      p {
        @include absolute(top 20px);
      }
    }

    sup {
      top: -.1em;
    }
  }
}