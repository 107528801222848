.projects {

  .title-1 {
    $space-top: 150px;
    position: relative;
    padding-top: 30px;

    @media screen and (min-width: $breakpoint-xs) {
      padding-top: 50px;
    }

    @media screen and (min-width: $breakpoint-md) {
      padding-top: 150px;
    }

    @include border-animation($space-top);
  }

  .project {
    position: relative;
    margin-bottom: $gutter-xs;

    background-size: cover;
    cursor: pointer;

    @media screen and (min-width: $breakpoint-xs) {
      margin-bottom: $gutter;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: 10px;

      &:hover {
        .project-title {
          display: block;
        }

        &:after {
          background-color: $rgba-bg-secondary;
        }
      }

      &-title {
        display: none;
      }
    }

    &:after {
      content: '';
      display: block;
      padding-top: 100%;
    }

    &--active,
    &--active:hover {
      &:after {
        background-color: $rgba-bg;
      }

      .project-title {
        display: block;
        @include absolute(top 50%);
        left: auto;
        right: auto;
        bottom: auto;
        width: 100%;
        padding: 40px;
        transform: translateY(-50%);
        text-align: center;

        &-main,
        &-sub {
          display: block;
          padding: 0;
          background: none;
        }

        &-sub,
        &-pin {
          color: #A09B00;
          @include font-size(16px);
        }

        &-sub {
          margin: 0;
          font-style: italic;
        }

        &-pin {
          display: block;

          .icon-pin {
            @include font-size(12px);
            margin-right: 5px;
          }
        }
      }

    }

    &-title {
      @include absolute(left 15px right 15px bottom 24px);

      &-main,
      &-sub {
        display: inline-block;
        background-color: $color-dark;

        color: $color-secondary;
      }

      &-main {
        padding: 10px 10px 6px 10px;

        text-transform: uppercase;
        font-family: $font-proximaBold;
        letter-spacing: 2px;
        @include font-size(24px);
      }

      &-sub {
        margin-left: 10px;
        padding: 0 10px 5px 10px;

        @include font-size(18px);
      }

      &-pin {
        display: none;
      }
    }
  }

  .post {
    position: relative;
    margin-bottom: $gutter-xs;
    padding: 15px 0;

    background-size: cover;
    background-position: center center;

    @media screen and (min-width: $breakpoint-xs) {
      margin-bottom: $gutter;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: 10px;
    }

    &:after {
      content: '';
      @include absolute(top 0 left 0 right 0 bottom 0);

      background-color: $rgba-bg;
    }

    &-wrapper {
      position: relative;
      z-index: $index-lowest;
      display: table;
      width: 100%;

      padding: 20px;

      border-top: 2px solid $color-primary;
      border-bottom: 2px solid $color-primary;

      color: #FFF;

      @media screen and (min-width: $breakpoint-sm) {
        padding: 20px 50px;
      }
    }

    &-arrow {
      @include font-size(12px);
      @include triangle(
              $direction: top,
              $position: bottom 100% left 0,
              $color: $color-primary
      );
    }

    &-close {
      @include absolute(top 20px right 0px);
      display: block;

      background: none;
      border: none;

      cursor: pointer;
      outline-width: 0;
      @include font-size(25px);
      color: #8E8E8E;

      @media screen and (min-width: $breakpoint-sm) {
        @include absolute(top 20px right 20px);
        @include font-size(35px);
      }
    }

    &-categories {
      margin-bottom: 20px;

      @media screen and (min-width: $breakpoint-sm) {
        text-align: center;
      }
    }

    &-category {
      display: inline-block;

      span {
        display: block;
        margin-right: 10px;
        @include size(45px);

        line-height: 45px;
        @include font-size(25px);

        border-radius: 50%;
        background-color: $color-dark;
        text-align: center;

        @media screen and (min-width: $breakpoint-xs) {
          @include size(54px);

          line-height: 54px;
          @include font-size(30px);
        }
      }

      &:last-child span {
        margin-right: 0;
      }
    }

    &-desc {
      font-family: $font-proximaLight;
      text-align: justify;
      @include font-size(18px);

      strong {
        font-family: $font-proximaBold;
      }
    }

    @media screen and (min-width: $breakpoint-sm) {
      &-categories {
        display: table-cell;
        vertical-align: middle;
        width: 70px * 4 + 20px;
        margin: 0;
        text-align: left;
      }

      &-desc {
        display: table-cell;
        padding: 55px 0;
      }
    }
  }
}