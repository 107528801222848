// Basic inputs
textarea,
select,
select option,
input[type=text],
input[type=password],
input[type=email],
input[type=phone],
input[type=number] {
  width: 100%;
  padding: 15px 20px;
  /*border: 1px solid transparent;*/
  border: none;


  background-color: $color-dark-grey;
  color: $color-light;
  font-family: inherit;

  &.error {
    border-color: $color-error;
  }

  &:active,
  &:focus {
    outline-color: $color-primary;
  }
}

button {
  outline-width: 0;
}

/*input[type="checkbox"] {
  @include visually-hidden;

  & + label {
    position: relative;
    padding-left: 1.6em;

    @include font($font-brandonRegular, normal, 22px);

    &:before {
      content: '';
      @include absolute(left 0 top 50%);
      display: block;
      width: 1em;
      height: 1em;

      margin-top: -.6em;

      border: 1px solid $color-light;
    }
    &:after {
      content: '✓';
      @include absolute(left 0 top 50%);
      display: block;
      width: 1em;
      height: 1em;
      margin-top: -.6em;

      text-align: center;
      opacity: 0;
      transition: opacity .4s ease;
    }

    &:hover:after {
      opacity: .2;
    }
  }
  &:checked + label {
    &:after {
      opacity: 1;
    }
  }
}*/