/* ----------------------------- */
/* == All fonts             */
/* ----------------------------- */

////
/// @group fonts
////

/// Proxima Regular
/// @type font
$font-proximaRegular: "proximanova regular", sans-serif;

/// Proxima Light
/// @type font
$font-proximaLight: "proximanova light", sans-serif;

/// Proxima Bold
/// @type font
$font-proximaBold: "proximanova bold", sans-serif;

/// Miso Light
/// @type font
$font-misoLight: "miso light", sans-serif;


////
/// @group font-sizes
////
$font-size-root: 16px;